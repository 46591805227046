import React, { useState, createContext } from 'react'

import { ListItem } from '../types/Lookup'

export const SponsoredAccountContext = createContext({

})

interface Props {
  children: React.ReactNode
}

export const SponsoredAccountProvider: React.FC<Props> = ({ children }) => {
  const [listItems, setListItems] = useState<ListItem[]>([])
  const [items, setItems] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<any>({ id: 'D_IS&T' })
  const [isLoading, setIsLoading] = useState<any>({ list: true, items: true })
  const [firstLoad, setFirstLoad] = useState<Boolean>(true)

  const exportValue = {
    listItems,
    setListItems,
    items,
    setItems,
    selectedItem,
    setSelectedItem,
    isLoading,
    setIsLoading,
    firstLoad,
    setFirstLoad
  }

  return (
    <SponsoredAccountContext.Provider value={exportValue}>
      {children}
    </SponsoredAccountContext.Provider>
  )
}

export const useSponsoredAccountContext = () => {
  const context = React.useContext(SponsoredAccountContext)
  if (context === undefined) {
    throw new Error('useSponsoredAccountContext must be used within app provider')
  }
  return context
}
