import { BaseController } from './BaseController'

export class PeopleSearchController extends BaseController {
  mapResponse (data: any) {
    return data.map((item: any) => {
      item.name = item.display_name
      item.id = item.mit_id
      return item
    })
  }

  async getPeople (searchTerm: string): Promise<any> {
    const response = await this.useFetch('GET', 'lookups/mit-person', `${this.apiHost}/${this.apiPathDigitalId}/search?q=${searchTerm}`)

    if (response.status === 200) {
      let data = await response.json()
      data = this.mapResponse(data)
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getSponsoredAccountSearch (searchTerm: string): Promise<any> {
    const response = await this.useFetch('GET', 'lookups/sponsor-account', `${this.apiHost}/${this.apiPath}/access/search?q=${searchTerm}`)

    if (response.status === 200) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getSearch (searchTerm: string): Promise<any> {
    const [people, sponsoredAccount]: any = await Promise.all([this.getPeople(searchTerm), this.getSponsoredAccountSearch(searchTerm)])
    return [...people, ...sponsoredAccount]
  }
}
