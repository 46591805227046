import { EventBus } from '@mit/aws-react'
import { BaseController } from '../api/controller'
import { useAppContext, useLookupsContext } from '../context'
import { useState, useEffect } from 'react';
import { appInitialized } from '@mit/aws-react';

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const { initializeApp: initAppState, initializeProfile, initializeProfilePicture, profile, profilePicture }: any = useAppContext()
  const [initializeApi, setInitializeApi] = useState(false)
  const { setAllocationTypes, setBuildings, setCoreFacilities, setDepartments, setTransportMethod }: any = useLookupsContext()
  let profileResSuccess = false

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false,
    });
  }, []);

  const initializeApp = async (): Promise<void> => {
    const loggedInUser = ''
    const baseController = new BaseController()

    await baseController
      .getUserAuth()
      .then((data) => {
        initAppState({ ...data, loggedInUser })
        setBuildings(data.lookups.buildings)
        setDepartments(data.lookups.departments)
        setAllocationTypes(data.lookups.allocation_types)
        setCoreFacilities(data.lookups.core_facilities)
        setTransportMethod(data.lookups.transport_methods)

        appInitialized({
          isLoading: false,
          hasAccess: data.authorized,
          hasError: false,
        });
        setInitializeApi(true)

        Promise.all([baseController.getProfile(), baseController.getProfilePicture()]).then(([profileRes, profilePictureRes]) => {
          initializeProfile(profileRes)
          initializeProfilePicture(profilePictureRes)
        })
      })
      .catch((error) => {
        appInitialized({
          isLoading: false,
          hasAccess: false,
          hasError: true,
        });
      })
  }

  return { initializeApp }
}
