import { BaseController } from './BaseController'

export interface VaccineStatusResp {
  exemption?: string
  exemption_approved: boolean
  number_of_shots: number
  proof_approved: boolean
  proof_submitted: boolean
  vaccination_status: 'full' | 'partial' | 'exempt' | 'none' | 'No Status'
  hold_status?: string
}

export interface VaccineStatusHoldResp {
  status?: string
}

export interface FetchExemptFlagsResponse {
  flags?: ExemptFlagModel
  error?: any
  unauth?: any
}

export interface ExemptFlagModel {
  medical: boolean
  hr: boolean
  international: boolean
  temp: boolean
}

export class VaccineController extends BaseController {
  readonly hold_path = 'access/hold'

  async getVaccineStatus (mit_id: string): Promise<VaccineStatusResp> {
    const response = await this.useFetch('GET', 'get/vaccinestatus', `${this.apiHost}/${this.apiPathVaccine}/vaccine/status/${mit_id}/overview`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getVaccineHoldStatus (mit_id: string): Promise<VaccineStatusHoldResp> {
    const response = await this.useFetch('GET', 'get/vaccineholdstatus', `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold/status?mit_id=${mit_id}`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getPendingTests (mit_id?: string): Promise<any> {
    const response = await this.useFetch('GET', 'get/testsPending', `${this.apiHost}/${this.apiPathMedical}/tests/pending${mit_id ? `?mitId=${mit_id}` : ''}`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async cancelPendingTests (mitId: string, accessionNumber: any): Promise<any> {
    const response = await this.useFetch(
      'DELETE',
      'delete/testsPending',
      `${this.apiHost}/${this.apiPathMedical}/tests/pending`,
      { mitId, accessionNumber }
    )

    if (response.status === 201 || response.status === 204) {
      const data = response.json()
      Promise.resolve(data)
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async fetchPersonVaccineExemption (mitId: string): Promise<FetchExemptFlagsResponse> {
    const response = await this.useFetch('GET', 'get/vaccineexemption', `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold/exemptions/flags/${mitId}`)

    if (response.status === 200) {
      const flags: ExemptFlagModel = await response.json()
      if (flags) {
        return { flags }
      } else {
        return { error: true }
      }
    } else {
      return { error: true }
    }
  }

  async putPersonVaccineExemption (mit_id: string, body: any): Promise<any> {
    const response = await this.useFetch(
      'PUT',
      'put/vaccineexemption',
      `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold/exemptions/flags/${mit_id}`,
      body
    )

    if (response.status === 201) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
