import { BaseController } from './BaseController'
import * as _ from 'lodash'
import { unpack } from 'jsonpack'

export class AllocationController extends BaseController {
  mapAllocations (data: any) {
    data.data = unpack(data.data)

    const buildings = _.groupBy(data.data.buildings, 'id')
    const cores = _.groupBy(data.data.cores, 'id')
    const types = _.groupBy(data.data.types, 'id')
    const pi = _.groupBy(data.data.pi, 'id')
    const depts = _.groupBy(data.data.depts, 'id')
    const transport_methods = _.groupBy(data.data.transport_methods, 'id')

    if (data.data.allocations) {
      data.allocations = data.data.allocations.map((item: any) => {
        item.person.name = `${item.person.first_name} ${item.person.last_name}`
        if (item.principal_investigator) {
          item.principal_investigator = pi[item.principal_investigator.toString()][0]
          item.principal_investigator.name = `${item.principal_investigator?.first_name} ${item.principal_investigator?.last_name}`
        }

        if (item.buildings && item.buildings.length > 0) {
          item.buildings = item.buildings.map((bldg: any) => {
            return buildings[bldg.toString()][0]
          })
        }

        if (item.cores && item.cores.length > 0) {
          item.cores = item.cores.map((core: any) => {
            return cores[core.toString()][0]
          })
        }

        item.allocation_type = types[item.allocation_type.toString()][0]

        if (item.transport_method) {
          item.transport_method = transport_methods[item.transport_method.toString()][0]
        }

        if (item.department) {
          item.department = depts[item.department][0]
        }

        return item
      })
    } else {
      data.allocations = []
    }

    data.data = null

    return data
  }

  async getAllocations (
    key: string,
    assignment_type_id: string,
    departmentId?: string | null,
    pi_mit_id?: string | null,
    kerberos_id?: string | null,
    building_key?: string | null,
    course_code?: string | null
  ): Promise<any> {
    const query: any = {
      dlc_key: departmentId,
      assignment_type_id,
      pi_mit_id,
      kerberos_id,
      building_key,
      course_code
    }
    const query_string = Object.keys(query)
      .filter((key: any) => query[key])
      .map((key: any) => `${key}=${encodeURIComponent(query[key])}`)
      .join('&')

    const response = await this.useFetch('GET', 'get/allocations', `${this.apiHost}/${this.apiPath}/allocations?${query_string}`)

    if (response.status === 200) {
      let data = await response.json()
      data = this.mapAllocations(data)
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async createAllocation (body: any): Promise<any> {
    const response = await this.useFetch('POST', 'allocations', `${this.apiHost}/${this.apiPath}/allocations`, body)

    if (response.status === 201) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getS3Url (file_name: any, allocation_type: any, action: any): Promise<any> {
    const response = await this.useFetch(
      'GET',
      'get/vcs_upload_url',
            `${this.apiHost}/${this.apiPath}/allocations/upload?file_name=${file_name}&allocation_type=${allocation_type}&action=${action}`
    )

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async uploadAccounts (url: string, file: any, fileName: any): Promise<any> {
    const response = await this.useFetch(
      'PUT',
      'vcs/upload',
      url,
      file,
      { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'Content-Filename': fileName },
      true
    )

    if (response.status === 200) {
      return true
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  // async uploadAllocation(file: any, fileName: any, userType: string): Promise<any> {

  //     let response = await this.useFetch('POST', 'allocations/upload', `${this.apiHost}/${this.apiPath}/allocations/upload?filename=${fileName}${userType ? `&allocation_type=${userType}` : ''}`, file, true, fileName);

  //     if (response.status === 201) {
  //         let data = response.json();
  //         return data;
  //     } else {
  //         let error = await response.json();
  //         return Promise.reject(error.message);
  //     }

  // }

  async updateAllocation (id: string, body: any): Promise<any> {
    const response = await this.useFetch('PUT', 'allocations', `${this.apiHost}/${this.apiPath}/allocations/${id}`, body)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async deleteAllocation (id: string): Promise<any> {
    const response = await this.useFetch('DELETE', 'allocations', `${this.apiHost}/${this.apiPath}/allocations/${id}`)

    if (response.status === 204) {
      // let data = response.json();
      return 'DELETED'
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async hasAllocation (id: string): Promise<any> {
    const response = await this.useFetch(
      'GET',
      'allocations/has_allocation',
            `${this.apiHost}/${this.apiPath}/allocations/has_allocation?mit_id=${id}`
    )

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
