import { BaseController } from './BaseController'

export class HoldController extends BaseController {
  readonly hold_path = 'hold'

  async getHold (mit_id: string): Promise<any> {
    const response = await this.useFetch('GET', 'get/hold', `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}?mit_id=${mit_id}`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async createHold (body: any): Promise<any> {
    const response = await this.useFetch('POST', 'post/hold', `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}`, body)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async deleteHold (mit_id: string): Promise<any> {
    const response = await this.useFetch('DELETE', 'post/hold', `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}?mit_id=${mit_id}`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
