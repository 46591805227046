import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Initialization } from '@mit/aws-react'
import { MITLoggedOut } from '@mit/hui'
import './scss/main.scss'
import '@fortawesome/fontawesome-pro/css/all.min.css'

import { AppProvider } from './context/AppContext'
import { useAppInitializer } from './hooks/Initializer'
import appConfig from './app-config-global'

const WrappedOKTAApp: FC = () => {
  const { initializeApp } = useAppInitializer()

  const AppLazyLoad = React.lazy(async () => await import('./App').then(module => ({
    default: module.default
  })))

  return (
    <>
      <Router>
        <React.StrictMode>
          <Switch>
            <Route exact path='/logout' component={MITLoggedOut} />
            <Route path='/'>
              <Initialization
                App={AppLazyLoad}
                appInitializer={initializeApp}
                appConfigData={appConfig}
                loaderProps={
                    {
                      contactEmail: 'help@mit.edu',
                      name: 'MIT Campus Access',
                      isLoading: true,
                      type: 'Default',
                      backgroundColor: '#0f393c'
                    }
                }
              />
            </Route>
          </Switch>
        </React.StrictMode>,
      </Router>
    </>
  )
}

ReactDOM.render(
  <React.StrictMode><AppProvider><WrappedOKTAApp /></AppProvider></React.StrictMode>,
  document.getElementById('root')
)
