const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPathDigitalId": "digital-id-v1",
      "apiPathCovid19": "access-v1",
      "apiPathVaccine": "vaccine-v1",
      "apiPathPass": "pass-v1",
      "apiPathMedical": "medical-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "apiPathDigitalId": "digital-id/v1",
      "apiPathCovid19": "access/v1",
      "apiPathVaccine": "vaccine/v1",
      "apiPathPass": "pass/v1",
      "apiPathMedical": "medical/v1"
    }
  }
};
export default apiConfigs;