import React, { useState, createContext } from 'react'

import { Lookup } from '../types/Lookup'
import { States } from '../api/mock/States'

export const LookupsContext = createContext({

})

interface Props {
  children: React.ReactNode
}

export const LookupsProvider: React.FC<Props> = ({ children }) => {
  const [allocationTypes, setAllocationTypes] = useState<Lookup[]>([])
  const [buildings, setBuildings] = useState<Lookup[]>([])
  const [coreFacilities, setCoreFacilities] = useState<Lookup[]>([])
  const [departments, setDepartments] = useState<Lookup[]>([])
  const [states, setStates] = useState<Lookup[]>(States)
  const [transportMethod, setTransportMethod] = useState<Lookup[]>([])

  const exportValue = {
    allocationTypes,
    setAllocationTypes,
    buildings,
    setBuildings,
    coreFacilities,
    setCoreFacilities,
    departments,
    setDepartments,
    states,
    setStates,
    transportMethod,
    setTransportMethod
  }

  return (
    <LookupsContext.Provider value={exportValue}>
      {children}
    </LookupsContext.Provider>
  )
}

export const useLookupsContext = () => {
  const context = React.useContext(LookupsContext)
  if (context === undefined) {
    throw new Error('useLookupsContext must be used within a LookupsProvider')
  }
  return context
}
