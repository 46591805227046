import React, { useState, createContext } from 'react'

import { SponsoredAccountProvider } from './SponsoredAccountContext'
import { SearchProvider } from './SearchContext'
import { FormProvider } from './FormContext'
import { LookupsProvider } from './LookupsContext'
import { AllocationProvider } from './AllocationContext'

export const AppContext = createContext({})

interface Props {
  children: React.ReactNode
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth]: any = useState({
    loggedInUser: '',
    authorized: false,
    max_cores: 7,
    max_buildings: 5,
    roles: {
      1: { view: false, edit: false },
      2: { view: false, edit: false },
      3: { view: false, edit: false },
      6: { view: false, edit: false },
      8: { view: false, edit: false },
      9: { view: false, edit: false },
      10: { view: false, edit: false },
      11: { view: false, edit: false },
      people_search: { view: false, edit: false },
      vcs_admin: { view: false, edit: false }
    }
  })
  const [profile, setProfile]: any = useState<object | null>(null)
  const [profilePicture, setProfilePicture] = useState<string | null>(null)
  const [refreshAllocations, setRefreshAllocations] = useState(0)
  const [isLoading, setIsLoading]: any = useState({
    buildings: true,
    departments: true,
    allocationTypes: true,
    coreFacilities: true,
    transportMethod: true
  })

  const initializeApp = (data: any) => {
    setAuth(data)
    // setPages(data.roles.map((item: any) => (cloneDeep(defaultPageState))))
  }
  const initializeProfile = (data: any) => {
    setProfile((state: any) => {
      return data
    })
  }

  const initializeProfilePicture = (data: any) => {
    const url = URL.createObjectURL(data)
    setProfilePicture((state: any) => {
      return url
    })

    // setProfilePicture(url);
  }

  const exportValue = {
    initializeApp,
    initializeProfile,
    initializeProfilePicture,
    auth,
    profile,
    setProfile,
    profilePicture,
    setProfilePicture,
    isLoading,
    setIsLoading,
    refreshAllocations,
    setRefreshAllocations
  }

  // const PiProvider = TypesContext[0].provider;
  // const PiContext = TypesContext[0].context;

  return (
    <AppContext.Provider value={exportValue}>

      <SponsoredAccountProvider>
        <SearchProvider>
          <FormProvider>
            <LookupsProvider>
              <AllocationProvider>
                {children}
              </AllocationProvider>
            </LookupsProvider>
          </FormProvider>
        </SearchProvider>
      </SponsoredAccountProvider>

    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider')
  }
  return context
}
