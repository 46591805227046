import { BaseController } from './BaseController'

export class CardSwipesController extends BaseController {
  waiv_until_path = 'pass/waive_until'

  async getSwipes (mit_id: string): Promise<any> {
    const response = await this.useFetch('GET', 'get/swipes', `${this.apiHost}/${this.apiPath}/access/card/swipes/${mit_id}`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
